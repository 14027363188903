import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

const ChapterList = () => {
  const [novel, setNovel] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState({ novel: true, chapters: true });
  const [error, setError] = useState({ novel: null, chapters: null });
  const chaptersPerPage = 100;
  const { id } = useParams();

  useEffect(() => {
    // Fetch novel details
    fetch(`/api/novels/${id}`)
      .then(response => {
        console.log('Novel response:', response);
        if (!response.ok) throw new Error(`Failed to fetch novel data: ${response.status} ${response.statusText}`);
        return response.json();
      })
      .then(data => {
        console.log('Novel data:', data);
        setNovel(data.data);
        setLoading(prev => ({ ...prev, novel: false }));
      })
      .catch(err => {
        console.error('Error fetching novel:', err);
        setError(prev => ({ ...prev, novel: err.message }));
        setLoading(prev => ({ ...prev, novel: false }));
      });

    // Fetch chapters
    fetch(`/api/novels/${id}/chapters`)
      .then(response => {
        if (!response.ok) throw new Error('Failed to fetch chapters data');
        return response.json();
      })
      .then(data => {
        console.log('Chapters data:', data);
        setChapters(data.data);
        setLoading(prev => ({ ...prev, chapters: false }));
      })
      .catch(err => {
        console.error('Error fetching chapters:', err);
        setError(prev => ({ ...prev, chapters: err.message }));
        setLoading(prev => ({ ...prev, chapters: false }));
      });
  }, [id]);

  if (loading.novel || loading.chapters) {
    return <div>Loading... Please wait.</div>;
  }

  if (error.novel || error.chapters) {
    return (
      <div>
        <h2>Error loading data:</h2>
        {error.novel && <p>Novel error: {error.novel}</p>}
        {error.chapters && <p>Chapters error: {error.chapters}</p>}
      </div>
    );
  }

  if (!novel || chapters.length === 0) {
    return <div>No data available for this novel.</div>;
  }

  const indexOfLastChapter = currentPage * chaptersPerPage;
  const indexOfFirstChapter = indexOfLastChapter - chaptersPerPage;
  const currentChapters = chapters.slice(indexOfFirstChapter, indexOfLastChapter);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div style={{ backgroundColor: '#1a1a1a', color: 'white', padding: '20px' }}>
      <div style={{ backgroundColor: '#2c2c2c', padding: '20px', borderRadius: '8px', marginBottom: '20px' }}>
        <h1 style={{ color: '#8e8eff' }}>{novel.title}</h1>
        <p>Updated {novel.last_updated}</p>
        <p>Status: {novel.status || 'Ongoing'}</p>
        <h2>{novel.title} Novel Chapters</h2>
        <p>List of most recent chapters published for the {novel.title} novel. A total of {chapters.length} chapters have been translated and the release date of the last chapter is {chapters[chapters.length - 1]?.last_updated}</p>
        <h3>Latest Release:</h3>
        <p style={{ color: '#8e8eff' }}>Chapter {chapters[chapters.length - 1]?.chapter_number}: {chapters[chapters.length - 1]?.title}</p>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <input type="text" placeholder="Enter Chapter No." style={{ padding: '10px', borderRadius: '4px', backgroundColor: '#2c2c2c', color: 'white', border: 'none' }} />
        <button style={{ padding: '10px 20px', backgroundColor: '#8e8eff', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>GO</button>
        <div>
          {[...Array(Math.ceil(chapters.length / chaptersPerPage))].map((_, i) => (
            <button 
              key={i} 
              onClick={() => paginate(i + 1)} 
              style={{ 
                margin: '0 5px', 
                padding: '10px 15px', 
                backgroundColor: currentPage === i + 1 ? '#8e8eff' : '#2c2c2c', 
                color: 'white', 
                border: 'none', 
                borderRadius: '4px', 
                cursor: 'pointer' 
              }}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
        {currentChapters.map((chapter) => (
          <div key={chapter.id} style={{ backgroundColor: '#2c2c2c', padding: '15px', borderRadius: '8px' }}>
            <Link to={`/novel/${id}/chapter/${chapter.id}`} style={{ color: 'white', textDecoration: 'none' }}>
              <h3>{chapter.title}</h3>
              <p>{new Date(chapter.last_updated).toLocaleDateString()}</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChapterList;