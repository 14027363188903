import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Settings } from 'lucide-react';

const styles = {
  container: {
    backgroundColor: '#1a1a1a',
    color: 'white',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    backgroundColor: '#2c2c2c',
    padding: '20px',
    position: 'relative',
  },
  title: {
    color: '#8e8eff',
    fontSize: '24px',
    marginBottom: '10px',
  },
  chapterTitle: {
    fontSize: '20px',
    fontWeight: 'normal',
  },
  settingsButton: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  content: {
    padding: '20px',
    flex: 1,
  },
  paragraph: {
    marginBottom: '20px',
    lineHeight: '1.6',
  },
  navigation: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    borderTop: '1px solid #444',
  },
  navButton: {
    padding: '10px 20px',
    backgroundColor: '#8e8eff',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: '16px',
  },
  homeButton: {
    backgroundColor: '#4a4a4a',
  },
  disabledButton: {
    backgroundColor: '#4a4a4a',
    cursor: 'not-allowed',
    opacity: 0.5,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    fontSize: '20px',
  },
  error: {
    padding: '20px',
    color: 'red',
  },
};

const Chapter = () => {
  const [chapter, setChapter] = useState(null);
  const [novel, setNovel] = useState(null);
  const [allChapters, setAllChapters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { novelId, chapterId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Fetch novel data
        const novelResponse = await fetch(`/api/novels/${novelId}`);
        if (!novelResponse.ok) throw new Error('Failed to fetch novel data');
        const novelData = await novelResponse.json();
        setNovel(novelData.data);

        // Fetch all chapters
        const chaptersResponse = await fetch(`/api/novels/${novelId}/chapters`);
        if (!chaptersResponse.ok) throw new Error('Failed to fetch chapters data');
        const chaptersData = await chaptersResponse.json();
        setAllChapters(chaptersData.data);

        // Find the current chapter
        const currentChapter = chaptersData.data.find(ch => ch.id.toString() === chapterId);
        if (currentChapter) {
          setChapter(currentChapter);
        } else {
          throw new Error('Chapter not found');
        }

        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [novelId, chapterId]);

  const navigateChapter = (direction) => {
    if (!chapter || allChapters.length === 0) return;

    const currentIndex = allChapters.findIndex(ch => ch.id === chapter.id);
    const newIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;

    console.log(`Current chapter: ${chapter.chapter_number}`);
    console.log(`New chapter index: ${newIndex}`);

    if (newIndex >= 0 && newIndex < allChapters.length) {
      const newChapter = allChapters[newIndex];
      console.log(`Navigating to chapter ${newChapter.chapter_number} with ID ${newChapter.id}`);
      navigate(`/novel/${novelId}/chapter/${newChapter.id}`);
    } else {
      console.log(`Cannot navigate ${direction}. Reached ${direction === 'next' ? 'last' : 'first'} chapter.`);
    }
  };

  if (loading) {
    return <div style={styles.loading}>Loading... Please wait.</div>;
  }

  if (error) {
    return <div style={styles.error}>Error: {error}</div>;
  }

  if (!chapter || !novel) {
    return <div style={styles.error}>No data available.</div>;
  }

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.title}>{novel.title}</h1>
        <h2 style={styles.chapterTitle}>Chapter {chapter.chapter_number}: {chapter.title}</h2>
        <button style={styles.settingsButton}>
          <Settings size={24} color="white" />
        </button>
      </header>
      
      <div style={styles.content}>
        {chapter.content.split('\n').map((paragraph, index) => (
          <p key={index} style={styles.paragraph}>{paragraph}</p>
        ))}
      </div>
      
      <div style={styles.navigation}>
        <button 
          onClick={() => navigateChapter('previous')} 
          disabled={chapter.chapter_number <= 1}
          style={chapter.chapter_number <= 1 ? {...styles.navButton, ...styles.disabledButton} : styles.navButton}
        >
          Previous
        </button>
        <Link to={`/novel/${novelId}`} style={{...styles.navButton, ...styles.homeButton}}>
          Home
        </Link>
        <button 
          onClick={() => navigateChapter('next')} 
          disabled={chapter.chapter_number >= allChapters.length}
          style={chapter.chapter_number >= allChapters.length ? {...styles.navButton, ...styles.disabledButton} : styles.navButton}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Chapter;