import React, { useState, useEffect } from 'react';
import NovelCard from './NovelCard';  // Ensure the correct path to NovelCard

const NovelList = () => {
  const [novels, setNovels] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('Fetching novels...');
    fetch('/api/novels')
      .then(response => response.json())
      .then(data => {
        console.log('Received data:', data);
        setNovels(data.data);
      })
      .catch(err => {
        console.error('Error fetching novels:', err);
        setError(err.toString());
      });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <section style={{ padding: '20px' }}>
      <h2 style={{ fontSize: '1.5rem', marginBottom: '20px' }}>Novels</h2>
      {novels.length === 0 ? (
        <p>Loading novels...</p>
      ) : (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {novels.map(novel => (
            <NovelCard key={novel.id} novel={novel} />
          ))}
        </div>
      )}
    </section>
  );
};

export default NovelList;
