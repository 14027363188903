import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import NovelList from './components/NovelList';
import ChapterList from './components/ChapterList';
import Chapter from './components/Chapter';

const LightNovelPub = () => {
  return (
    <Router>
      <div style={{ 
        backgroundColor: '#1a1a1a', 
        color: 'white', 
        minHeight: '100vh',
        fontFamily: 'Arial, sans-serif'
      }}>
        <header style={{
          backgroundColor: '#2c2c2c',
          padding: '10px 20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Light Novel Pub</div>
          </Link>
          <nav>
            {['Browse', 'Ranking', 'Updates', 'Filter', 'DEV'].map((item) => (
              <button key={item} style={{
                backgroundColor: 'transparent',
                border: 'none',
                color: 'white',
                margin: '0 10px',
                cursor: 'pointer'
              }}>{item}</button>
            ))}
          </nav>
          <div>
            {['Search', 'Profile', 'Library'].map((item) => (
              <button key={item} style={{
                backgroundColor: 'transparent',
                border: 'none',
                color: 'white',
                margin: '0 10px',
                cursor: 'pointer'
              }}>{item}</button>
            ))}
          </div>
        </header>

        <Routes>
          <Route path="/" element={<NovelList />} />
          <Route path="/novel/:id" element={<ChapterList />} />
          <Route path="/novel/:novelId/chapter/:chapterId" element={<Chapter />} />
        </Routes>
      </div>
    </Router>
  );
};

export default LightNovelPub;