import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const NovelCard = ({ novel }) => {
  const [imageSrc, setImageSrc] = useState(`/api/novels/${novel.id}/cover_image`);
  const [imageError, setImageError] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const handleError = () => {
    console.error(`Failed to load image: ${novel.title}`);
    setImageError(true);
    setImageSrc('/path/to/fallback/image.png'); // Path to a fallback image
  };

  const handleDownload = async () => {
    setDownloading(true);
    try {
      const response = await fetch(`/api/novels/${novel.id}/download`);
      if (!response.ok) throw new Error('Download failed');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${novel.title}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error);
      alert('Failed to download PDF. Please try again.');
    } finally {
      setDownloading(false);
    }
  };

  return (
    <div style={{
      backgroundColor: '#2c2c2c',
      borderRadius: '8px',
      overflow: 'hidden',
      margin: '10px',
      width: '200px'
    }}>
      <Link to={`/novel/${novel.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        <img 
          src={imageSrc} 
          onError={handleError} 
          alt={novel.title} 
          style={{ width: '100%', height: '300px', objectFit: 'cover' }} 
        />
        {imageError && <p style={{ color: 'red' }}>Image not available</p>}
        <div style={{ padding: '10px' }}>
          <h3 style={{ fontSize: '1rem', marginBottom: '5px' }}>{novel.title}</h3>
          <p style={{ fontSize: '0.8rem' }}>By: {novel.author || 'Unknown'}</p>
        </div>
      </Link>
      <button 
        onClick={handleDownload} 
        disabled={downloading}
        style={{
          width: '100%',
          padding: '10px',
          backgroundColor: '#8e8eff',
          color: 'white',
          border: 'none',
          cursor: downloading ? 'not-allowed' : 'pointer',
          opacity: downloading ? 0.7 : 1,
        }}
      >
        {downloading ? 'Downloading...' : 'Download PDF'}
      </button>
    </div>
  );
};

export default NovelCard;